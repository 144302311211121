import { Country } from "../models/shared/Country";
import { UnitState } from "../models/shared/UnitState";

export function validateEmail(email: string): boolean {
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}

export function isNullOrWhitespace(value: string | null | undefined): boolean {
  if (value == null || value.trim().length == 0)
    return true;
  else
    return false;
}

export function formatOrgNr(orgNr?: string, country: "no" | "se" | Country = "no") {

  if (country == "se" || country == Country.SE) {
    if (orgNr && orgNr.length === 10)
      return `${orgNr.substring(0, 6)}-${orgNr.substring(6, 10)}`;
    if (orgNr && orgNr.length === 12)
      return `${orgNr.substring(0, 2)}-${orgNr.substring(2, 8)}-${orgNr.substring(8, 12)}`;
    return orgNr;
  }

  if (orgNr == null || orgNr.length != 9)
    return orgNr;

  return `${orgNr.substr(0, 3)} ${orgNr.substr(3, 3)} ${orgNr.substr(6, 3)}`
}

export function formatPhoneNr(phoneNr?: string) {
  if (phoneNr == null)
    return phoneNr;

  if (phoneNr.startsWith("+47"))
    return phoneNr.substr(0, 3) + " " + phoneNr.substr(3, 3) + " " + phoneNr.substr(6, 2) + " " + phoneNr.substr(8, 3);
  if (phoneNr.startsWith("+46")) {
    if (phoneNr[3] === '7')
      return phoneNr.substring(0, 3) + " " + phoneNr.substring(3, 5) + "-" + phoneNr.substring(5, 8) + " " + phoneNr.substring(8, 10) + " " + phoneNr.substring(10, 12);
    else
      return phoneNr;
  }

  return phoneNr;
}

const states = [
  { id: UnitState.Received, title: "Mottatt", short: "M" },
  { id: UnitState.Checked, title: "Kontrollert", short: "U" },
  { id: UnitState.Preparing, title: "Henges opp", short: "U" },
  { id: UnitState.Prepared, title: "Hengt opp", short: "U" },

  { id: UnitState.Stop, title: "Stopp", short: "S" },
  { id: UnitState.Canceled, title: "Kanselert", short: "S" },
  { id: UnitState.Sandblast, title: "Sandblåsing", short: "S" },
  { id: UnitState.External, title: "Ekstern behandling", short: "S" },

  { id: UnitState.Packaging, title: "Pakkes", short: "E" },
  { id: UnitState.Packaged, title: "Pakket", short: "E" },
  { id: UnitState.Ready, title: "Utveid", short: "E" },
  { id: UnitState.Delivered, title: "Levert", short: "E" },
  { id: UnitState.Paint, title: "Lakkeres", short: "L" },
]

export function getStates() {
  return states;
}

export function toSerialNumber(type?: EntityType, id?: number | null) {
  if (id == null)
    return null;

  if (type == null)
    return id.toString();

  if (type == EntityType.WorkOrder)
    return `O${id + 10000}`;
  if (type == EntityType.Order)
    return `B${id + 10000}`;
  if (type == EntityType.Customer)
    return `K${id + 1000}`;
  if (type == EntityType.Unit)
    return `E${id + 100000}`;
  if (type == EntityType.User)
    return `P${id + 1000}`;
  if (type == EntityType.Article)
    return `A${id + 100}`;
  if (type == EntityType.Invoice)
    return `F${id + 10000}`;
  if (type == EntityType.Offer)
    return `T${id + 10000}`;
  if (type == EntityType.Complaint)
    return `R${id + 1000}`;
  if (type == EntityType.Carrier)
    return `C${id + 100}`;
  return id.toString();
}

export enum EntityType {
  WorkOrder,
  User,
  Unit,
  RushRequest,
  Order,
  Offer,
  Invoice,
  Customer,
  Contact,
  Boom,
  Article,
  Address,
  Complaint,
  TransportOrder,
  Carrier,
  Expence
}