import * as React from "react";
import { IListDefinition, Alignment, FilterType } from "../../../components/lists/IListDefinition";
import { List } from "../../../components/lists/List";
import { CustomerListModel } from "../../../models/shared/CustomerListModel";
import { default as RootStore } from "../../../stores/root-store";
import { Link } from "react-router-dom";
import { toDateWithHM, since } from "../../../core/dateHelpers";
import { TextFilter } from "../../../components/lists/FilterComponents/TextFilter";
import { SortDirection } from "../../../models/shared/SortDirection";
import PersonIcon from "svg/person.svg";
import CompanyIcon from "svg/company.svg";
import { formatOrgNr, toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { NumberWithPostfix } from "../../../components/shared/display";
import { Country } from "../../../models/shared/Country";
import { SE as SwedenFlag } from 'country-flag-icons/react/1x1'

const config: IListDefinition<CustomerListModel> = {
  id: "customers",
  singular: "Kunde",
  editLink: (row) => `/customers/${row.Id}/edit`,
  createLink: user => user.isCustomer ? null : '/customers/create',
  rowCssClass: (row) => row.IsInsolvent ? "insolvent" : "",
  columns: [
    {
      title: "",
      render: (row, value) => <td>
        {row.Country === Country.SE ? (<SwedenFlag className="icon-gray" />) : (
          row.OrganizationId == null ? <PersonIcon className="icon-person" /> : <CompanyIcon className="icon-company" />)}
      </td>
    },
    {
      title: "Id",
      sortable: true,
      field: "Id",
      render: (row, value) => <td>{toSerialNumber(EntityType.Customer, row.Id)}</td>
    },
    {
      field: "Name",
      sortable: true,
      title: "Navn",
      render: (row, value) => {
        return row.Deleted == null ? <td><Link to={`/customers/${row.Id}`}>{value}</Link></td> : <td>{value}</td>
      }
    },
    {
      field: "OrganizationId",
      title: "Org. nummer",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap" x-ms-format-detection="none">{formatOrgNr(value, row.Country)}</td>,
    },
    {
      field: "Created",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDateWithHM(value)}</span></td>,
      sortable: true,
      title: "Opprettet"
    },
    {
      field: "Count_Users",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right">{value}</td>,
      sortable: true,
      title: "Brukere"
    },
    {
      field: "Count_WorkOrders",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right">{value}</td>,
      sortable: true,
      title: "Arbeidsordre"
    },
    {
      field: "Total_Weight",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><NumberWithPostfix number={value} post="kg" /></td>,
      sortable: true,
      title: "Vekt"
    },
    {
      field: "Last_On",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right no-wrap"><span title={since(value)}>{toDateWithHM(value)}</span></td>,
      sortable: true,
      title: "Sist pålogget"
    },
    {
      field: "InvoiceDueDays",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right">{value} dager</td>,
      sortable: true,
      title: "Fakturadager"
    },
    {
      field: "DiscountPercentage",
      alignment: Alignment.Right,
      render: (row, value) => <td className="right">{value}%</td>,
      sortable: true,
      title: "Avslag",
      hidden: (user) => user.isCustomer
    }
  ],
  filters: [
    {
      field: "Id",
      title: "Kundenr.",
      component: <TextFilter />,
      type: FilterType.String
    },
    {
      field: "Name",
      title: "Navn",
      focus: true,
      component: <TextFilter />,
      type: FilterType.String
    },
    {
      field: "OrganizationId",
      title: "Org. nummer",
      component: <TextFilter />,
      type: FilterType.String
    }
  ],
  presets: [
    {
      default: true,
      title: "Opprettet",
      filters: [],
      sort: [{
        field: "Created",
        direction: SortDirection.Desc
      }]
    }
  ]
}

export class CustomerList extends React.Component {
  render() {
    return <List configuration={config} store={RootStore.getListStore(config)} />
  }
}