import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FormActions } from "../../../components/forms/FormActions";
import { FormErrors } from "../../../components/forms/FormErrors";
import { EnumField } from "../../../components/forms/fields/EnumField";
import { InputField } from "../../../components/forms/fields/InputField";
import { Form } from "../../../components/forms/form";
import { EntityType } from "../../../core/stringHelpers";
import { ExpenceEditModel } from "../../../models/shared/ExpenceEditModel";
import { FormStore } from "../../../stores/form-store";
import { Enums } from "../../../stores/lookup-store";

interface Props {
  id?: number;
}

@observer
export class ExpenceEditForm extends React.Component<Props> {
  @observable store?: FormStore<ExpenceEditModel>;

  async componentDidMount() {

    this.store = new FormStore<ExpenceEditModel>({}, {
    });

    if (this.props.id)
      this.store.loadModel("expences", this.props.id);
  }

  render() {
    if (this.store == null)
      return null;

    if (this.props.id && !this.store.isEdit)
      return null;


    return (

      <Form
        name="Utgifter"
        entityType={EntityType.Expence}
        cancelable={true}
        className="edit-form edit-form-padding"
        redirectToList={true}
        store={this.store}>
        <InputField<ExpenceEditModel>
          field="WorkOrderId"
          label="Arbeidsordre"
          helpText="Nummer på arbeidsordre"
        />
        <InputField<ExpenceEditModel>
          field="Amount"
          label="Sum"
          helpText="Sum uten pålogg og moms"
          postfix="kr"
          type="currency"
          inputMode="decimal"
        />
        <InputField<ExpenceEditModel>
          field="Supplier"
          label="Leverandør"
          helpText="Navn på leverandøren"
        />
        <EnumField<ExpenceEditModel>
          field="Type"
          label="Utgiftstype"
          helpText="Type utgift"
          enumType={Enums.ExpenceType}
          sort={false}
          required={true}
        />
        <InputField<ExpenceEditModel>
          field="Description"
          label="Beskrivelse"
        />
        <InputField<ExpenceEditModel>
          field="SupplierReference"
          label="Fakturanummer"
          helpText="Nummeret på leverandørens faktura"
        />
        <InputField<ExpenceEditModel>
          field="TripletexId"
          label="FakturaId"
          type="number"
          inputMode="numeric"
          helpText="Tripletex Invoice Id"
        />
        <FormErrors type="Utgiften" />
        <FormActions asFooter={true} />
      </Form>

    )
  }
}


