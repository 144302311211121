import * as React from 'react';
import { createRoot } from 'react-dom/client';
import 'normalize.css';
import "../scss/index.scss";
import Main from "./app/main";
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { Provider } from 'mobx-react';
import { Router, withRouter, RouteComponentProps } from 'react-router';
import { default as RootStore } from './stores/root-store';
import { v4 as guid } from "uuid";
import { Logger } from './core/logging';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { NotificationHub } from './core/signalR';

//moment.locale("no");

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, RootStore.RouterStore);

const queryClient = new QueryClient();

// Configure logging page id
(window as any).PageCorrelationID = guid();

browserHistory.listen(l => {
  const pageCorrelationId = guid();
  (window as any).PageCorrelationID = pageCorrelationId;
  const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  NotificationHub.sendNavigationData({ pathname: l.pathname, search: l.search, state: l.state ?? null, pageCorrelationId, agent: navigator.userAgent, language: navigator.language, darkMode });
})

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
function createApp() {
  Logger.info("Client application started.");
  root.render(
    <Provider {...RootStore}>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <Main />
        </QueryClientProvider>
      </Router>
    </Provider>);
}

document.addEventListener("keydown", event => {

  if (event.altKey == true) {
    const c = event.key.toUpperCase();
    if (c == "U")
      history.push("/users");
    if (c == "K")
      history.push("/customers");
    if (c == "B")
      history.push("/orders");
    if (c == "O")
      history.push("/workorders");
    if (c == "H")
      history.push("/rushrequests");
    if (c == "T")
      history.push("/offers");

    if (c == "N") {
      var path = history.location.pathname.split('/');
      if (path.length > 1) {
        if (["users", "customers", "orders", "workorders", "offers"].indexOf(path[1].toLowerCase()) >= 0) {
          history.push(`${path[1]}/create`);
        }
      }
    }

    if (c == "S") {
      document.getElementById("search-box")?.focus();
    }
  }
});

createApp();

// Fix for outlook
document.body.addEventListener("dragover", ev => {
  if (ev?.dataTransfer != null && ev.dataTransfer.dropEffect !== "copy") {
    ev.dataTransfer.dropEffect = "copy";
    ev.preventDefault();
  }
})
