import React from "react";
import { RouteComponentProps } from "react-router";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { ExpenceEditForm } from "./ExpenceForm";
import { ExpenceList } from "./ExpenceList";

interface Props {
  route: RouteComponentProps<{}>;
}

export class ExpenceContainer extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="utgift"
      plural="utgifter"
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<ExpenceEditForm/>}
      listComponent={<ExpenceList />}
    />
  }
}