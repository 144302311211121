import React from "react";
import { RouteComponentProps, Switch, Route } from "react-router";
import { WorkSpace } from "../../../components/shared/WorkSpace";
import { CarrierDetailsPage } from "./CarrierDetails";
import { CarrierEditForm } from "./CarrierForm";
import { CarrierList } from "./CarrierList";

interface Props {
  route: RouteComponentProps<{}>;
}

export class CarrierContainer extends React.Component<Props> {
  render() {
    return <WorkSpace
      singular="transportør"
      plural="transportører"
      routeBasePath={this.props.route.location.pathname.split("/")[1]}
      {...this.props.route}
      editComponent={<CarrierEditForm />}
      listComponent={<CarrierList/>}
      detailsComponent={<CarrierDetailsPage/>}
    />
  }
}