import * as React from "react"
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";
import { Filters } from "../../../components/lists/Filters";
import { IListDefinition, FilterType } from "../../../components/lists/IListDefinition";
import { CustomerListModel } from "../../../models/shared/CustomerListModel";
import { default as RootStore } from "../../../stores/root-store";
import { DateRangeFilter } from "../../../components/lists/FilterComponents/DateRangeFilter";
import { TextFilter } from "../../../components/lists/FilterComponents/TextFilter";
import "./CustomerMap.tsx.scss";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { CustomerMapModel } from "../../../models/shared/CustomerMapModel";
import { Link } from "react-router-dom";
import 'react-leaflet-fullscreen/dist/styles.css';
import FullscreenControl from 'react-leaflet-fullscreen';
import * as L from "leaflet";

const config: IListDefinition<CustomerListModel> = {
  id: "customersMap",
  singular: "Kunde",
  baseUrl: "/api/customers",
  listUrlSegment: "map",
  createLink: _ => null,
  showDelete: _ => false,
  columns: [],
  filters: [
    {
      title: "Periode",
      field: "LastDelivery",
      component: <DateRangeFilter />,
      type: FilterType.DateRange
    },
    {
      title: "Opprettet",
      field: "Created",
      component: <DateRangeFilter />,
      type: FilterType.DateRange
    },
    {
      title: "Minumum vekt",
      field: "Weight",
      component: <TextFilter />,
      type: FilterType.String,
    },
  ],
  presets: [
    {
      default: true,
      title: "Navn",
      filters: [],
      sort: []
    }
  ]
}

const svg: string = '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M 8 2 L 8 6 L 4 6 L 4 48 L 15 48 L 15 39 L 19 39 L 19 48 L 30 48 L 30 6 L 26 6 L 26 2 L 8 2 z M 10 10 L 12 10 L 12 12 L 10 12 L 10 10 z M 14 10 L 16 10 L 16 12 L 14 12 L 14 10 z M 18 10 L 20 10 L 20 12 L 18 12 L 18 10 z M 22 10 L 24 10 L 24 12 L 22 12 L 22 10 z M 32 14 L 32 18 L 34 18 L 34 20 L 32 20 L 32 22 L 34 22 L 34 24 L 32 24 L 32 26 L 34 26 L 34 28 L 32 28 L 32 30 L 34 30 L 34 32 L 32 32 L 32 34 L 34 34 L 34 36 L 32 36 L 32 38 L 34 38 L 34 40 L 32 40 L 32 42 L 34 42 L 34 44 L 32 44 L 32 48 L 46 48 L 46 14 L 32 14 z M 10 15 L 12 15 L 12 19 L 10 19 L 10 15 z M 14 15 L 16 15 L 16 19 L 14 19 L 14 15 z M 18 15 L 20 15 L 20 19 L 18 19 L 18 15 z M 22 15 L 24 15 L 24 19 L 22 19 L 22 15 z M 36 18 L 38 18 L 38 20 L 36 20 L 36 18 z M 40 18 L 42 18 L 42 20 L 40 20 L 40 18 z M 10 21 L 12 21 L 12 25 L 10 25 L 10 21 z M 14 21 L 16 21 L 16 25 L 14 25 L 14 21 z M 18 21 L 20 21 L 20 25 L 18 25 L 18 21 z M 22 21 L 24 21 L 24 25 L 22 25 L 22 21 z M 36 22 L 38 22 L 38 24 L 36 24 L 36 22 z M 40 22 L 42 22 L 42 24 L 40 24 L 40 22 z M 36 26 L 38 26 L 38 28 L 36 28 L 36 26 z M 40 26 L 42 26 L 42 28 L 40 28 L 40 26 z M 10 27 L 12 27 L 12 31 L 10 31 L 10 27 z M 14 27 L 16 27 L 16 31 L 14 31 L 14 27 z M 18 27 L 20 27 L 20 31 L 18 31 L 18 27 z M 22 27 L 24 27 L 24 31 L 22 31 L 22 27 z M 36 30 L 38 30 L 38 32 L 36 32 L 36 30 z M 40 30 L 42 30 L 42 32 L 40 32 L 40 30 z M 10 33 L 12 33 L 12 37 L 10 37 L 10 33 z M 14 33 L 16 33 L 16 37 L 14 37 L 14 33 z M 18 33 L 20 33 L 20 37 L 18 37 L 18 33 z M 22 33 L 24 33 L 24 37 L 22 37 L 22 33 z M 36 34 L 38 34 L 38 36 L 36 36 L 36 34 z M 40 34 L 42 34 L 42 36 L 40 36 L 40 34 z M 36 38 L 38 38 L 38 40 L 36 40 L 36 38 z M 40 38 L 42 38 L 42 40 L 40 40 L 40 38 z M 10 39 L 12 39 L 12 44 L 10 44 L 10 39 z M 22 39 L 24 39 L 24 44 L 22 44 L 22 39 z M 36 42 L 38 42 L 38 44 L 36 44 L 36 42 z M 40 42 L 42 42 L 42 44 L 40 44 L 40 42 z"/></svg>';

const customIcon = L.divIcon({
  className: "map-factory-icon",
  iconAnchor: [10, 10],
  iconSize: [30, 20],
  popupAnchor: [0, -28],
  html: svg
})

const factories: Array<{
  name: string, location: [number, number]
}> = [{
  location: [62.790173, 7.539383],
  name: "Molde Zink AS"
},
{
  location: [62.259266, 5.797928],
  name: "Vestsink AS"
},
{
  location: [60.345637, 5.130331],
  name: "Zinken AS"
},
{
  location: [58.670498, 5.647870],
  name: "NOT Varmforzinking AS"
},
{
  location: [59.251297, 10.258287],
  name: "DOT DuoZink AS"
},
{
  name: "FerroZink Trondheim AS",
  location: [63.341653, 10.358273]
},
{
  name: "RåZink AS",
  location: [59.340521, 10.948115]
},
{
  location: [67.259993, 15.374390],
  name: "Galvano Tia AS"
}, {
  location: [66.608090, 19.845653],
  name: "GalvTeknik i Norr AB"
},
{
  location: [64.738617, 21.050404],
  name: "Gallac Produktion AB"
},
{
  location: [64.174620, 17.366903],
  name: "Gallac Produktion AB"
},
{
  name: "Degerfors Förzinknings AB, DEFAB",
  location: [59.248143, 14.416383]
},
{
  name: "Nya Älta Galvan AB",
  location: [59.255029, 18.199654]
},
{
  location: [59.208898, 17.646338],
  name: "Södertälje Varmförzinkning AB"
},
{
  location: [59.344011, 16.480165],
  name: "DOT AB"
},
{
  location: [58.916910, 15.873151],
  name: "Häfla Bruk AB"
},
{
  location: [59.120034, 12.894698],
  name: "DOT Säffle AB"
},
{
  location: [58.323563, 11.495761],
  name: "DOT Lysekil AB"
},
{
  location: [58.441367, 14.909105],
  name: "Vadstena Varmförzinkning AB"
},
{
  name: "ProZink AB",
  location: [57.850462, 14.806353]
},
{
  name: "DOT Jönköping AB",
  location: [57.759469, 14.140070]
},
{
  location: [57.827086, 13.419854],
  name: "Zinken-Weland i Ulricehamn AB"
},
{
  location: [56.661664, 12.876771],
  name: "DOT Halmstad AB"
},
{
  name: "DOT AB",
  location: [56.042096, 14.581516]
},
{
  name: "Smekab Citylife AB",
  location: [56.048851, 14.019912]
},
{
  location: [55.473340, 13.589039],
  name: "DOT Rydsgård AB"
}]


// https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
// https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=norges_grunnkart&zoom={z}&x={x}&y={y}

export const CustomerMap: React.FC<{}> = observer(({ }) => {

  const [store] = React.useState(() => {
    const store = RootStore.getListStore(config);
    store.refresh();
    return observable(store);
  });

  const data = store.values as any as Array<CustomerMapModel>;

  return <div className="customer-map list">
    <Filters
      configuration={config}
      store={RootStore.getListStore(config)}
    >
    </Filters>
    <Map className="markercluster-map" center={[59.3, 10.94]} zoom={9} maxZoom={18}>
      <TileLayer
        url="https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png"
        attribution="&copy; Kartverket"
      />
      <FullscreenControl position="topright" />
      {/* Put <MarkerClusterGroup {...props} /> inside react-leaflet after <TileLayer /> */}
      <MarkerClusterGroup>
        {data && data
          .filter(c => c.Location != null)
          .map(c => <Marker key={c.Id} position={[c.Location!.coordinates[1], c.Location!.coordinates[0]]} title={c.Name} >
            <Popup className="customer-popup">
              <Link to={`/customers/${c.Id}`}>{c.Name}</Link>
              {c.Line1 && <span>{c.Line1}</span>}
              {c.Line2 && <span>{c.Line2}</span>}
              <span>{c.PostalCode} {c.City}</span>
            </Popup>
          </Marker>)}
      </MarkerClusterGroup>
      {factories.map((f, i) =>
        <Marker position={f.location} icon={customIcon} title={f.name} key={i}>
          <Popup>
            <span>{f.name}</span>
          </Popup>
        </Marker>
      )}
    </Map>
  </div>
});
