import { List } from "../../../components/lists/List";
import RootStore from "ts/stores/root-store";
import React from "react"
import { Link } from "react-router-dom"
import { Alignment, IListDefinition } from "../../../components/lists/IListDefinition"
import { toSerialNumber, EntityType } from "../../../core/stringHelpers"
import { CarrierListModel } from "../../../models/shared/CarrierListModel"

const config: IListDefinition<CarrierListModel> = {
  id: "carriers",
  singular: "Transportør",
  createLink: _ => "/carriers/create",
  editLink: (row) => `/carriers/${row.Id}/edit`,
  columns: [
    {
      title: "Id",
      sortable: true,
      field: "Id",
      render: (row, value) => <td>{toSerialNumber(EntityType.Carrier, row.Id)}</td>
    },
    {
      title: "Navn",
      sortable: true,
      field: "Name",
      render: (row, value) => <td className="no-wrap"><Link to={`/carriers/${row.Id}/edit`}>{row.Name}</Link></td>
    },
    {
      title: "Valgbar av kunde",
      sortable: true,
      field: "CustomerSelectable",
      alignment: Alignment.Center,
      render: (row, value) => <td className="no-wrap center">{value ? "Ja" : "Nei"}</td>
    },
    {
      title: "Tilknyttet kunde",
      sortable: true,
      field: "CustomerId",
      render: (row, value) => <td className="no-wrap">{value ? <Link to={`/customers/${row.CustomerId}`}>{row.CustomerName}</Link> : ""}</td>
    },
    {
      title: "Bestillingsepost",
      sortable: false,
      field: "OrderEmail",
      render: (row, value) => <td className="no-wrap">{value}</td>
    },
    {
      title: "Begrensninger",
      sortable: false,
      field: "Restrictions",
      alignment: Alignment.Center,
      render: (row, value) => <td className="no-wrap center">{(row.Restrictions ?? []).length > 0 ? "Ja" : "Nei"}</td>
    },
  ],
  filters: [],
  presets: []
}

export class CarrierList extends React.Component {
  render() {
    return <List configuration={config} store={RootStore.getListStore(config)} />
  }
}