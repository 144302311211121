import React from "react";
import { CarrierEditModel } from "../../../models/shared/CarrierEditModel";
import { FormStore } from "../../../stores/form-store";
import { Form } from "../../../components/forms/form";
import { EntityType } from "../../../core/stringHelpers";
import { FormActions } from "../../../components/forms/FormActions";
import { InputField } from "../../../components/forms/fields/InputField";
import { BooleanField } from "../../../components/forms/fields/BooleanField";

interface AddCarrierDialogProps {
  customerId: number;
  onCloseFn?: () => void;
  onAddCarrier: () => void;
}

export class AddCarrierDialog extends React.Component<AddCarrierDialogProps> {

  store: FormStore<CarrierEditModel>;

  constructor(props: AddCarrierDialogProps) {
    super(props);
    this.store = new FormStore<CarrierEditModel>({

    }, {
      CustomerId: props.customerId,
      CustomerSelectable: true,
      InvoiceCustomerDirectly: false,
      Restrictions: undefined
    });
  }

  render() {
    const { onCloseFn } = this.props;

    return <div className="rush-request-dialog">
      <p>Her kan legge til en egen transportør.</p>
      <div>
        <Form
          name=""
          entityType={EntityType.Carrier}
          cancelable={true}
          onSubmit={async (router) => {
            const id = await this.store.postForm("/api/carriers/add");
            this.props.onAddCarrier();
            if (id)
              onCloseFn!();
          }}
          onCancel={() => onCloseFn!()}
          store={this.store}>
          <InputField<CarrierEditModel>
            field="Name"
            label="Navn"
            helpText="Navn på transportør"
            autoFocus
          />
          <InputField<CarrierEditModel>
            field="OrderEmail"
            label="Epost for bestilling"
            helpText="Epostadresse som bestillinger skal sendes til"
            inputMode="email"
            type="email"
          />
          <BooleanField<CarrierEditModel>
            field="InvoiceCustomerDirectly"
            label="Samlet faktura"
            helpText="Her kan du velge om du ønsker faktura på transport seperat fra transportør eller samlet med varmforsinking fra RåZink"
            options={["Transportør sender faktura til kunde direkte", "RåZink fakturerer varmforsinking og transport samlet"]}
          />
          <FormActions asFooter={true} />
        </Form>
      </div>

    </div>
  }
}