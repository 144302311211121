import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { CustomerSelector } from "../../shared/CustomerSelector";

interface OwnProps {
  showAlphabeth?: boolean;
}

type P = PropsFromWrapperToField<number> & OwnProps;

class CustomerField extends React.Component<P> {

  setValue = (customerId: number | undefined) => {
    this.props.setValue(customerId);
    this.props.setTouched();
  }

  render() {
    const { id, value, showAlphabeth } = this.props;
    return <CustomerSelector
      value={value}
      setValue={this.setValue}
      showAlphabeth={showAlphabeth ?? true}
      id={id}
    />
  }
}

const wrapped = Wrap<P, number>(CustomerField);

export {
  wrapped as CustomerField
}