import * as React from "react";
import { useEffect, useState } from "react";
import { faCaretDown, faCaretUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { includes } from "lodash-es";
import { observer } from "mobx-react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthenticationHelper } from "../../../api/authentication";
import { Network } from "../../../api/network";
import { NotificationHub } from "../../../core/signalR";
import { UnreadCountModel } from "../../../models/shared/UnreadCountModel";
import { default as RootStore } from "../../../stores/root-store";
import { UserStore } from "../../../stores/user-store";
import "./Menu2.scss";

export const Menu2: React.FC = observer(({ }) => {
  const user = RootStore.UserStore;

  const [items, setItems] = useState<Array<MenuItem>>(getMenuItems(user, null));

  const updateCount = async () => {
    const result = await new Network().get<UnreadCountModel>(`/api/lookup/unreadCount`);
    if (result)
      setItems(getMenuItems(user, result));
  }

  useEffect(() => {
    updateCount();

    const changeTypesMonitored = ["WorkOrderEntity", "OrderEntity", "RushRequestEntity", "ComplaintEntity", "TransportOrderEntity", "BankReconciliationEntity"];
    const disposer = NotificationHub.registerListener(changes => {
      if (changes.some(ch => includes(changeTypesMonitored, ch.entityName)))
        updateCount();
    });

    return () => {
      NotificationHub.removeListener(disposer);
    };
  }, [user]);

  // Oppdater tall i menyen f.eks ved nytt døgn
  useEffect(() => {
    const intervalId = setInterval(() => updateCount(), 1000 * 60 * 60 /* hver time */);
    return () => clearInterval(intervalId);
  }, []);

  const closeMenu = () => RootStore.UIStore.menuIsOpen = false;

  return <nav className={classNames("menu", { "menu--open": RootStore.UIStore.menuIsOpen })}>
    <button onClick={e => closeMenu()} className="mobile-menu-close "><FontAwesomeIcon icon={faTimes} /></button>
    {items.filter(i => i.visible !== false).map((i, c) => <MenuBlock item={i} key={`${window.location.href}_${c}`} />)}
  </nav >
});

const MenuBlock: React.FC<{ item: MenuItem }> = ({ item }) => {

  const children = (item.children ?? []).filter(i => i.visible !== false);

  return <div className={classNames("nav-level1-group")}>
    <HeaderMenuItem item={item}>
      <span>{item.title}</span>
      {item.count && item.count.map((c, l) => <Count {...c} key={l} />)}
      {children.length > 0 && <FontAwesomeIcon icon={faCaretDown} className="nav-menu-item-caret nav-menu-item-closed" />}
      {children.length > 0 && <FontAwesomeIcon icon={faCaretUp} className="nav-menu-item-caret nav-menu-item-open" />}
    </HeaderMenuItem>

    {children && <div className="nav-level2-group">{
      children.map((c, i) => <HeaderMenuItem item={c} key={i}><span>{c.title}</span>{c.count && c.count.map((d, l) => <Count {...d} key={l} />)}</HeaderMenuItem>)}
    </div>}
  </div>
}

function isActive(item: MenuItem, path: string): boolean {

  let active = item.children && item.children.some(c => isActive(c, path));
  return active || path.toLowerCase() === item.url?.toLowerCase();
}


const HeaderMenuItem: React.FC<React.PropsWithChildren<HeaderMenuItemProps>> = ({ item, children, }) => {
  const { onClick, url } = item;
  const location = useLocation();

  const cssClass = classNames("nav-menu-item", { "nav-menu-item--active": isActive(item, location.pathname) });

  const clear = (fn?: () => void) => {
    const element = document.activeElement;
    if (element) {
      (element as HTMLElement).blur();
    }
    RootStore.UIStore.menuIsOpen = false;
    fn?.();
  }

  if (onClick)
    return <a href="#" onClick={e => clear(onClick)} className={cssClass} >{children}</a>
  if (url)
    return <NavLink to={url} className={cssClass} onClick={e => clear()} >{children}</NavLink>

  return <div className={cssClass} tabIndex={0}>{children}</div>;
}

const Count: React.FC<MenuItemCount> = ({ type, value }) => {
  if (value == undefined || value == 0)
    return null;
  return <span className={`unread-count unread-count--${type.toLowerCase()}`}>{value}</span>
}

interface HeaderMenuItemProps {
  item: MenuItem
}

interface MenuItemCount {
  value?: number;
  type: "bouble" | "box",
  visible?: boolean;
}

interface MenuItem {
  title: string;
  url?: string;
  onClick?: () => void;
  children?: Array<MenuItem>;
  visible?: boolean;
  count?: Array<MenuItemCount>
}

const getMenuItems = (user: UserStore, count: UnreadCountModel | null): Array<MenuItem> => [
  {
    title: "Brukere",
    url: "/users",
    children: [
      { title: user.isCustomer ? "Legg til kontakt" : "Legg til", url: "/users/create" }
    ]
  },
  {
    title: user.isCustomer ? "Kunde" : "Kunder",
    url: user.isCustomer ? `/customers/${user.customerId}` : "/customers",
    children: [
      { title: "Legg til", url: "/customers/create", visible: !user.isCustomer },
      { title: "Vektliste", url: "/customers/weight", visible: !user.isCustomer },
      { title: "Kart", url: "/customers/map", visible: !user.isCustomer },
      { title: "Adresser", url: "/addresses" }
    ]
  }, {
    title: "Bestillinger",
    count: [
      { value: count?.PendingOrderTransport, type: "bouble", visible: user.isEmployee || user.isAdministrator },
      { value: count?.Orders, type: "box", visible: !user.isCustomer }
    ],
    url: "/orders",
    children: [{ title: "Legg til", url: "/orders/create" }]

  }, {
    title: "Tilbud",
    url: "/offers",
    children: [
      { title: "Legg til", url: "/offers/create" },
      { title: "Kalkuler", url: "/offers/calculate" }
    ],
    visible: !user.isCustomer
  }, {
    title: "Arbeidsordre",
    count: [
      { value: count?.PendingWorkOrderTransport, type: "bouble", visible: user.isAdministrator || user.isEmployee },
      { value: count?.WorkOrders, type: "box", visible: !user.isCustomer }
    ],
    url: "/workorders",
    children: [
      { title: "Legg til", url: "/workorders/create", visible: !user.isCustomer },
      { title: "Plan", url: "/workorders/plan", visible: !user.isCustomer },
      { title: "Dagsoversikt", url: "/workorders/days", visible: !user.isCustomer },
      { title: "Statusoversikt", url: "/workorders/state", visible: !user.isCustomer },
    ]
  }, {
    title: "Hast",
    url: "/rushrequests",
    count: [{ value: count?.RushRequests, type: "bouble", visible: !user.isCustomer }]
  },
  {
    title: "Reklamasjoner",
    url: "/complaints",
    visible: !user.isCustomer,
    count: [
      { value: count?.PendingComplaints, type: "bouble", visible: user.isAdministrator },
      { value: count?.PendingComplaintsActive, type: "box", visible: !user.isCustomer }
    ],
  },
  {
    title: "Økonomi",
    visible: user.isAdministrator,
    count: [{ value: count?.PendingCardTransactions, type: "box" }],
    children: [
      { title: "Fakturert", url: "/workorders/earnings" },
      { title: "Månedsestimat", url: "/finance/monthly" },
      { title: "Forfalte fakturaer", url: "/finance/dueInvoices" },
      { title: "Statistikk", url: "/statistics" },
      { title: "Nøkkeltall", url: "/statistics/keyfigures" },
      { title: "Kortbruk", url: "/finance/cardusage", count: [{ value: count?.PendingCardTransactions, type: "bouble" }] },
      { title: "Utgifter", url: "/expences" }
    ]
  },
  {
    title: "Transport",
    visible: user.isAdministrator,
    children: [
      { title: "Transportører", url: "/carriers" },
    ]
  },
  {
    title: "Lønn",
    visible: user.isAdministrator,
    children: [
      { title: "Timeføring", url: "/employees/hours" },
      { title: "Lønnslipper", url: "/employees/payslips" },
    ]
  },
  {
    title: "Administrasjon",
    visible: user.isAdministrator,
    children: [
      { title: "Sidevarsler", url: "/pagealerts" },
      { title: "Artikler", url: "/articles" },
    ]
  },
  {
    title: user.name!,
    url: `/users/${user.userId}`,
    visible: window.innerWidth < 768,
    children: [
      { title: "Bytt passord", url: "/users/changePassword" },
      { title: "Logg ut", onClick: () => new AuthenticationHelper().logout() },
    ]
  }
]
