import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../../forms/wrapField";
import TashIcon from "svg/trash.svg"
import AddIcon from "svg/add.svg"
import { Button } from "ts/components/shared/Button";
import "./RepeatField.tsx.scss";
import { observer } from "mobx-react";

interface OwnProps {
  container: (component: React.ReactElement<any>, values?: Array<any>) => React.ReactElement<any>;
  children: (
    test: any,
    onChange: (field: string, value: any) => void,
    addComponent: React.ReactElement<any>,
    index: number
  ) => React.ReactElement<any>;
  addText: string;
}

type P = PropsFromWrapperToField<Array<any>> & OwnProps;

@observer
class RepeatField extends React.PureComponent<P> {

  removeItem = (id: number) => {
    const { value, setValue, setTouched } = this.props;
    if (value) {
      let copy = [...value];
      copy.splice(id, 1)
      setValue(copy);
      setTouched();
    }
  }

  addItem = () => {
    const { value, setValue, setTouched } = this.props;
    if (value)
      setValue([...value, {}]);
    else
      setValue([{}]);
    setTouched();
  }

  render() {
    const { value, setValue, setTouched, id, autoFocus, container, children, addText } = this.props;

    let elements: Array<any> = [];

    elements = (value || []).map((element, index) => children(element, (field, v) => {
      let copy = value || [{}];
      copy[index][field] = v;
      setValue(copy);
      setTouched();
    }, <span className="remove-action" onClick={e => this.removeItem(index)}><TashIcon className="icon" /></span>,
      index
    ));
    

    return <div className="repeat-field" id={id}>
      {container(<>{elements}</>, value)}
      <div className="add-action"><Button onClick={this.addItem} icon={AddIcon} text={addText} /></div>
    </div>

  }
}

const A = Wrap<P, Array<any>>(RepeatField)
export { A as RepeatField };
