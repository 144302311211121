import { observer } from "mobx-react";
import * as React from "react";
import { observable } from "mobx";
import { FormStore } from "../../../stores/form-store";
import { Form } from "../../../components/forms/form";
import { FormActions } from "../../../components/forms/FormActions";
import RootStore from "ts/stores/root-store";
import { EntityType } from "../../../core/stringHelpers";
import { BooleanField } from "../../../components/forms/fields/BooleanField";
import { InputField } from "../../../components/forms/fields/InputField";
import { CarrierEditModel } from "../../../models/shared/CarrierEditModel";
import { CustomerField } from "../../../components/forms/fields/CustomerField";
import { FormErrors } from "../../../components/forms/FormErrors";

interface Props {
  id?: number;
}

@observer
export class CarrierEditForm extends React.Component<Props> {
  @observable store?: FormStore<CarrierEditModel>;

  async componentDidMount() {

    this.store = new FormStore<CarrierEditModel>({}, {
    });

    if (this.props.id)
      this.store.loadModel("carriers", this.props.id);
  }

  render() {
    if (this.store == null)
      return null;

    if (this.props.id && !this.store.isEdit)
      return null;

    const user = RootStore.UserStore;

    return (

      <Form
        name="Transportør"
        entityType={EntityType.Carrier}
        cancelable={true}
        className="edit-form edit-form-padding"
        redirectToList={true}
        store={this.store}>
        <InputField<CarrierEditModel>
          field="Name"
          label="Navn"
          helpText="Navn på transportør."
          autoFocus
        />
        <InputField<CarrierEditModel>
          field="OrderEmail"
          label="Epost for bestilling"
          helpText="Epostadresse som bestillinger skal sendes til."
          inputMode="email"
          type="email"
        />
        <BooleanField<CarrierEditModel>
          field="CustomerSelectable"
          label="Kan velges av kunde"
          helpText="Indikerer om transportøren kan velges av kunder ved bestilling"
        />
        <CustomerField<CarrierEditModel>
          field="CustomerId"
          label="Knyttet til kunde"
          showAlphabeth={false}
          helpText="Indikerer om transportøren er knyttet til en spesifik kunde som kan redigere navn og epost"
          showIf={row => row.CustomerSelectable === true}
        />
        <BooleanField<CarrierEditModel>
          field="InvoiceCustomerDirectly"
          label="Samlet faktura"
          helpText="Her kan du velge om du ønsker faktura på transport seperat fra transportør eller samlet med varmforsinking fra RåZink"
          options={["Transportør sender faktura til kunde direkte", "RåZink fakturerer varmforsinking og transport samlet"]}
          showIf={row => row.CustomerSelectable === true}
        />
        <FormErrors type="Transportøren" />
        <FormActions asFooter={true} />
      </Form>

    )
  }
}


